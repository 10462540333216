import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const classNames = {
  container: 'flex justify-between items-center h-16 max-w-[1240px] mx-auto px-4 text-grey-400',
  title: 'w-full text-3xl font-bold text-[#00Af8a]',
  navList: 'hidden md:flex',
  navItem: 'p-4',
  sideNavTitle: 'w-full text-3xl font-bold text-[#009f6a] m-4',
  sideNavItem: 'p-4 border-b border-gray-600'
};
const ClassNamesContext = React.createContext(classNames);

const Navbar2 = () => {
  const classNames = React.useContext(ClassNamesContext);

  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Pricing", path: "/pricing" },
  ];
  
  return (
    <div className={classNames.container}>
    <h1 className={classNames.title}>DocsGPT</h1>
    <ul className={classNames.navList}>
        {navItems.map(item => (
            <li className={classNames.navItem} key={item.name}>
              <a href={item.path}>
                {item.name}
              </a>
            </li>
        ))}
    </ul>
    <div onClick={handleNav} className='scale-y-90 block z-20 md:hidden'>
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <ul className={'fixed z-10 top-0 w-[50vw] h-full border-l border-r border-gray-600 bg-[#DADCDF] ease-in-out duration-500 ' + (nav ? 'left-[50vw]' : 'left-[100vw]')}>
        <h1 className={classNames.sideNavTitle}> </h1>
        {navItems.map(item => (
          <li className={classNames.sideNavItem} key={item.name}>
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
export default Navbar2;
