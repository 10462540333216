import React from 'react';
import Laptop from '../assets/laptop.jpg';

const Privacy = () => {
  const paragraphClassName = 'py-4';
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid'>
        <div className='flex flex-col justify-center'>
          <p className='text-[#00df9a] font-bold text-4xl'>Privacy Policy</p>
          <p>
            Last updated: Feb 8 2023
          </p>

          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Overview</h1>
          <p>
            <div className={paragraphClassName}>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://copyedit.ai (the “Site”).</div>

            <div className={paragraphClassName}>Our Privacy Policy explains how we collect, use, protect, and share information about our customers who use our Copyedit.ai app (the "App"). We collect and store certain information about our customers. This information includes:</div>

            <ul className="list-disc list-inside">
              
              <li>Email address</li>
              <li>Usage information, such as whether the customer has opened or used the App</li>
              <li>Other information related to the customers use of the App</li>
            </ul>
            
            <div className={paragraphClassName}>We use the information we collect to:</div>

            <ul className="list-disc list-inside">
              <li>Provide, maintain, and improve the Copyedit.ai app</li>
              <li>Communicate with customers about the App</li>
              <li>Develop new features and services</li>
            </ul>
            <div className={paragraphClassName}>We may share customer information with third parties, such as our partners and service providers, in order to provide, maintain, and improve the App. We do not share customer information with third parties for their own marketing purposes.</div>

            <div className={paragraphClassName}>We take reasonable measures to protect customer information from unauthorized access, disclosure, alteration, or destruction.</div>

            <div className={paragraphClassName}>If you have questions about our Privacy Policy or our data practices, you may contact us at from.copyeditai.support@ondea.com . If you would like to delete any of your personal information we have collected, please contact us at the same address.</div>

          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
