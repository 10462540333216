// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDzcaurCV7mllq4pTxDDL_Qn1VNERV4L3U",
  authDomain: "copyeditai.firebaseapp.com",
  databaseURL: "https://copyeditai-default-rtdb.firebaseio.com",
  projectId: "copyeditai",
  storageBucket: "copyeditai.appspot.com",
  messagingSenderId: "68560954632",
  appId: "1:68560954632:web:ab2f54620a907b080e78bd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const https = httpsCallable;
