import React, {useState, useRef, useEffect} from 'react';
import { GoogleButton } from 'react-google-button';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Typed from 'react-typed';
import ActivityIndicator from 'react-activity-indicator';

const Hero = () => {
  const { googleSignIn, user } = UserAuth();
  const navigate = useNavigate();
  let typed = useRef(null);
  const [showIndicator, setShowIndicator] = React.useState(false)
  const [typingIndex, setTypingIndex] = useState(-1);
  const buttonClassLists = "inline w-[200px] rounded-md font-medium my-6 mx-auto py-3";
  const primaryFgColor = '[#00df9a]';
  const primaryBgColor = 'white';
  const TAGLINE="Customize your Google Docs™️\n with the AI that powers\nChatGPT!";
  let selectionIndex = 0;
  const [taglineSelected, setTaglineSelected] = React.useState("");
  const [taglineUnselected, setTaglineUnselected] = React.useState(TAGLINE);
  
  const getButtonClassNames = (index) => {
    return buttonClassLists + ((typingIndex === index) ?
        ` bg-${primaryFgColor} text-${primaryBgColor}` :
        ` bg-${primaryBgColor} text-${primaryFgColor}`) 
  }
  const [switched, setSwitched] = React.useState(true);
  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  function replaceWithItems(parentEl, items) {
    parentEl.replaceChildren();
    for (var i = 0; i < items.length; i++) {
      var el = document.createElement('span');
      parentEl.append(el);
      el.classList.add('item');
      el.innerHTML = items[i];
    }
  }

  const SLICE_SIZE = 3;
  const BLUR_ENABLED = false;
  const LABELS = ['Casual 🧢','Creative 🎨','Formal 🎩','Poetic 🎭','Technical 📐', '🗣 Shakespeare']

  function populateSlices(rootIndex) {
    var rootEl = document.getElementById(`spinner${rootIndex}`);
    rootEl.replaceChildren();

    for (var i = 0; i < 1; i += SLICE_SIZE) {
      var el = document.createElement('div')
      rootEl.append(el);
      el.classList.add('container'+rootIndex);
      el.style=`top: ${i}px;`
      el.style.left = (rootIndex == 1) ? '0px' : '-500px';

      var parentEl = el;

      el = document.createElement('div')
      parentEl.append(el);
      el.classList.add('blurry');

      parentEl = el;

      el = document.createElement('div')
      parentEl.append(el);
      el.classList.add('text');
      el.style=`top: ${SLICE_SIZE - i}px;`
      if (rootIndex == 1) {
        replaceWithItems(el, [' ', ' ', ' ']);
      } else {
        replaceWithItems(el, LABELS.concat(LABELS).slice(3, 6));
      }
    }
  }

  const spinTo = (itemNames, itemNames2 = null) => {
    var container1Digit = switched ? '2' : '1';
    var container2Digit = switched ? '1' : '2';

    [].forEach.call(document.getElementsByClassName(`container${container1Digit}`), (el) => {
      var textEl = el.children[0].children[0];
      replaceWithItems(textEl, itemNames);
    });
    [].forEach.call(document.getElementsByClassName('container1'), (el) => {
      el.classList.remove(`container${container1Digit}-anim`);
    });
    [].forEach.call(document.getElementsByClassName('container2'), (el) => {
      el.classList.remove(`container${container2Digit}-anim`);
    });
    if (BLUR_ENABLED) {
      [].forEach.call(document.getElementsByClassName('blurry'), (el) => {
        el.classList.remove('blurry-anim');
      });
    }
    setSwitched(!switched);
    container1Digit = switched ? '1' : '2';
    container2Digit = switched ? '2' : '1';
    if (itemNames2) {
      setTimeout(() => {
        [].forEach.call(document.getElementsByClassName(`container${container1Digit}`), (el) => {
          var textEl = el.children[0].children[0];
          replaceWithItems(textEl, itemNames2);
        });
      }, 400);
    }
    setTimeout(() => {
      [].forEach.call(document.getElementsByClassName('container1'), (el) => {
        el.classList.add(`container${container1Digit}-anim`);
        el.style.left = switched ? '-500px' : '0px';
      });
      [].forEach.call(document.getElementsByClassName('container2'), (el) => {
        el.classList.add(`container${container2Digit}-anim`);
        el.style.left = switched ? '0px' : '-500px';
      });
      if (BLUR_ENABLED) {
        [].forEach.call(document.getElementsByClassName('blurry'), (el) => {
          el.classList.add('blurry-anim');
        });
      }
      //[].forEach((el) => el.addClass('container1-anim'), document.getElementsByClassName('container1'));
    }, 100);
  }

  useEffect(() => {
    if (user != null) {
      navigate('/account');
    }
  }, [user]);

  function selectNext() {
    selectionIndex++;
    if (selectionIndex == 11) {
      selectionIndex = 38;
    }
    if (selectionIndex == 42) {
      selectionIndex = 58;
    }
    const unselectedText = TAGLINE.substring(selectionIndex);
    setTaglineSelected(TAGLINE.substring(0, selectionIndex));
    setTaglineUnselected(unselectedText);
    //setShowIndicator(true);
    if (unselectedText) {
      setTimeout(() => {
        selectNext();
      }, 30);
      }
}

  useEffect(() => {
    console.log('useEffect!!!!' + user);
    if (user != null) {
      return;
    }
    for (var j = 1; j <= 2; j += 1) {
      populateSlices(j);
    }

  //   if (typingIndex === -1) {
    console.log('setTimeout');
    setTimeout(() => {
      selectNext();
      spinTo(LABELS.concat(LABELS).slice(5, 8), LABELS.concat(LABELS).slice(0, 3));
      document.getElementById('selector').style = 'opacity: 1;';
    }, 2000);
    setTimeout(() => {
      setTypingIndex(0);
      setShowIndicator(true);
    }, 4000);
    setTimeout(() => { 
      document.getElementById('typed').hidden = false;
      setShowIndicator(false);
      typed.current.start();
    }, 5500);
  }, []);
  
  // useEffect(() => {
  // }, [typingIndex]);

  return (
    <div className='text-black'>
      <div className='max-w-[800px] w-full mx-auto text-center flex flex-col justify-center'>
        {/* <p className='text-[#00df9a] font-bold p-2'>
          DocsGPT
        </p> */}
        <h1 className='md:text-5xl sm:text-4xl text-3xl font-bold md:py-4'>
        Bring the power of ChatGPT to your docs
        </h1>
        {/* <div id="buttons" className='flex justify-center items-center'>
          <button className={getButtonClassNames(0)}>Casual 🧢</button>
          <button className={getButtonClassNames(1)}>Creative 🎨</button>
          <button className={getButtonClassNames(2)}>Formal 🎩</button>
          <button className={getButtonClassNames(3)}>Poetic 🎭</button>
          <button className={getButtonClassNames(4)}>Technical 📐</button>
          <button className={getButtonClassNames(5)}>In the style of: <span className='underline'>Shakespeare</span></button>
        </div> */}
        <div className='flex justify-center items-center self-center'>
          <div id='selector'></div>
          <div id='spinner-container'>
            <div id='spinner1'></div>
            <div id='spinner2'></div>
          </div>
          <div id="letterbox">
            <div id="shadow-box">
            </div>
            </div>
          </div>
          <div className='h-[13px] relative'>
              <div class="bg"></div>
              <div class="bg2"></div>
              <div class="bg3"></div>
              <div class="bg4"></div>

              <div class="short lines"></div>
              <div class="tall lines"></div>
              <div class="triangle"></div>
              <div class="bar"></div>
              <div class="triangle2"></div>
            </div>
          <div class="docs-area">
            {/* {(typingIndex < 6) ? */}
              <div className="h-[100px] w-[800px] flex justify-center items-start sm:h-[300px]">
                {(typingIndex === -1) ?
                    <div id="initial-text" className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'>
                      <span className="bg-blue-200 leading-none sm:leading-[56px] whitespace-pre">{taglineSelected}</span>
                      <span id={taglineSelected ? null : 'blinking-cursor'} className="leading-none sm:leading-[56px] whitespace-pre">{taglineUnselected}</span>
                    </div>
                : null}
                <div className="absolute centered m-1">
                  {showIndicator ?
                      <span class="inline md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2 typed-cursor typed-cursor--blink">▮</span>
                      // <ActivityIndicator
                      //     number={3}
                      //     diameter={10}
                      //     duration={200}
                      //     activeColor="#888"
                      //     borderColor="#BBB"
                      //     borderWidth={1}
                      //     borderRadius="50%" />
                  : null
                  // <span class="inline md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2 typed-cursor typed-cursor--blink">▮</span>
                  }
                </div>
                <div id="typed" hidden>
                  <Typed
                    className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
                    typedRef={ (typedInstance) => typed.current = typedInstance }
                    typeSpeed={30}
                    stopped={true}
                    fadeOut={true}
                    cursorChar="▮"
                    fadeOutDelay={2000}
                    backDelay={1500}
                    startDelay={2000}
                    onComplete={(self) => {
                      console.log('onComplete');
                    //   typed.current.stop();
                    //   typed.current.arrayPos = 0;
                    //   typed.current.start();
                    }}
                    preStringTyped={(arrayPos, self) => {
                      //console.log('preStringTyped', arrayPos, self);
                      document.getElementById('typed').hidden = false;
                      setShowIndicator(false);
                    }}
                    onStop={(arrayPos, self) => {
                      //console.log('onStop', arrayPos, self);
                    }}
                    onStringTyped={(arrayPos, self) => {
                      const moddedTypingIndex = typingIndex % 6;
                      //console.log('onStringTyped', arrayPos, self);

                      spinTo(LABELS.concat(LABELS).slice(moddedTypingIndex, moddedTypingIndex + 3));
                      setTimeout(() => { 
                        setTypingIndex((arrayPos + 1) % 6);
                        setShowIndicator(true);
                        document.getElementById('typed').hidden = true;
                    }, 2000);
                    }}
                    loop
                    strings={[
                      // Version 1
                      "Hey! Check out DocsGPT and get your documents cranking with the AI from ChatGPT!",
                      "Google Docs™️ and the AI of ChatGPT: the perfect marriage to upgrade your documents!",
                      "DocsGPT leverages the power of ChatGPT's AI to enhance the quality of documents significantly.",
                      "Liberate your words with the AI behind ChatGPT!",
                      "DocsGPT facilitates a substantial improvement of documents through the integration of the AI from ChatGPT.",
                      "Yon Google Docs™️ be customized anon, by the power of ChatGPT's AI! 'Tis a wise decision, for sure, to choose such a potent force.",

                      // Version 2
                      "Wow! Write docs on steroids with DocsGPT and the power of ChatGPT's AI!",
                      "Unlock stellar document creations with ChatGPT's AI by using DocsGPT!",
                      "Take advantage of the AI technology powering ChatGPT and unlock greater potential with DocsGPT's document enhancement capabilities.",
                      "Elevate your documents to new heights with the AI of ChatGPT!",
                      "DocsGPT leverages the AI engine of ChatGPT to increase the quality of a user's documents.",
                      "A rose by any other name would still smell as sweet, but with DocsGPT it will look even better.",

                      // Version 3
                      "DocsGPT is here to make your docs awesome with the help of ChatGPT's AI!",
                      "Harness the might of ChatGPT's AI in your documentation with DocsGPT.",
                      "DocsGPT is equipped with the AI technology of ChatGPT to revolutionize document creation and optimization.",
                      "Let ChatGPT's AI fuel your words with DocsGPT!",
                      "DocsGPT harnesses the power of ChatGPT's AI to provide an enhanced experience for users editing their documents.",
                      "Ah, the AI of ChatGPT with Google Docs™️, a match made in the heavens above.",
                    ]}
                  />
                </div>
              {/* : setTypingIndex(-1)} */}
            </div>
          </div>
        {/* <p className='md:text-2xl text-xl font-bold text-gray-500'>Monitor your data analytics to increase revenue for BTB, BTC, & SASS platforms.</p> */}
        {/* <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'>Get Started</button> */}
        <div className="m-5">Install the Google Docs™️ add-on</div>
        <GoogleButton className='m-1 mx-auto text-black' onClick={handleGoogleSignIn} />
      </div>
    </div>
  );
};

const Button = ({text}) => (
  <button className='border py-2 px-5 mt-10'>
    {text}
  </button>
);

const Link = ({text}) => (
  <a className='py-2 px-5'>
    {text}
  </a>
);

export default Hero;
