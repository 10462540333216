import React from 'react';
import Laptop from '../assets/laptop.jpg';

const Refunds = () => {
  const paragraphClassName = 'py-4';
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid'>
        <div className='flex flex-col justify-center'>
          <p className='text-[#00df9a] font-bold text-4xl'>Refund Policy</p>
          <p>
            Last updated: Feb 8 2023
          </p>

          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Overview</h1>
          <p>
            <div className={paragraphClassName}>Thanks for purchasing our products (or subscribing to our services) at Copyedit.ai. We offer a full money-back guarantee for all purchases made on our website. If you are not satisfied with the product that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 5 calendar days of your purchase. After the 5-day period you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the product (or service) in the first two weeks after their purchase to ensure it fits your needs. If you have any additional questions or would like to request a refund, feel free to contact us at support@copyeditai.zendesk.com</div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Refunds;
