import { useContext, createContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth } from '../firebase';
import { functions, https } from '../firebase';
import { httpsCallable } from 'firebase/functions';

console.log(functions);
console.log();

const createStripeCheckout1 = httpsCallable('createStripeCheckout');
const createStripeCheckout2 = https('createStripeCheckout');
const createStripeCheckout3 = functions.httpsCallable;
console.log(createStripeCheckout1);
// console.log(createStripeCheckout1());
console.log(createStripeCheckout2);
// console.log(createStripeCheckout2());
console.log(createStripeCheckout3);
// console.log(createStripeCheckout3());
const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  //console.log(https.createStripeCheckout);
  const [user, setUser] = useState({});

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    // signInWithPopup(auth, provider);
    signInWithRedirect(auth, provider)
  };

  const logOut = () => {
      signOut(auth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      console.log('User', currentUser)
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ googleSignIn, logOut, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
