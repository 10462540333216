import React from 'react';
import Single from '../assets/single.png'
import Double from '../assets/double.png'
import Triple from '../assets/triple.png'
// import functions from 'firebase';
// import { functions } from '../firebase';
import { UserAuth } from '../context/AuthContext';

const checkout = () => {
  console.log('checkout');
}

const Cards = ({isMarketing}) => {
  const { googleSignIn, user} = UserAuth();
  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };
  let url = null;
  console.log("isMarketing:" +isMarketing)
  if (!isMarketing && user && user.email) {
    const encoded = user.email.split('').map((a) => a.charCodeAt(0).toString(16).padStart(2, '0')).join('');
    url = `https://buy.stripe.com/test_aEU9Ez2jdezhcgg002?client_reference_id=${encoded}`;
  }
  if (isMarketing || url) {
    return (
      <div className='w-full py-[10rem] px-4 rgb(245, 246, 247)'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
          <a href={url ? url : null} onClick={url ? null : checkout}>
            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
                <img className='w-20 mx-auto mt-[-3rem] bg-white' src={Single} alt="/" />
                <h2 className='text-2xl font-bold text-center py-8'>Basic Plan</h2>
                <p className='text-center text-4xl font-bold'>$2.95 / month</p>
                <div className='text-center font-medium'>
                    <p className='py-2 border-b mx-8 mt-8'>40,000 letters of AI response</p>
                    <p className='py-2 border-b mx-8'>(about 8,500 words)</p>
                </div>
                <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Select</button>
            </div>
          </a>
          <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-3rem] bg-transparent' src={Double} alt="/" />
              <h2 className='text-2xl font-bold text-center py-8'>Premium Plan</h2>
              <p className='text-center text-4xl font-bold'>$9.95 / month</p>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>200,000 letters of AI response</p>
                  <p className='py-2 border-b mx-8'>(about 42,000 words)</p>
              </div>
              <button className='bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Start Trial</button>
          </div>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-3rem] bg-white' src={Triple} alt="/" />
              <h2 className='text-2xl font-bold text-center py-8'>Professional Plan</h2>
              <p className='text-center text-4xl font-bold'>$29.95 / month</p>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>1 Million letters of AI response</p>
                  <p className='py-2 border-b mx-8'>(about 200,000 words)</p>
              </div>
              <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Start Trial</button>
          </div>
        </div>
      </div>
    );
  }
};

export default Cards;
