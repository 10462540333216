import React, {useState, useRef, useEffect} from 'react';

import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Protected from './components/Protected';
import { AuthContextProvider } from './context/AuthContext';
import Account from './pages/Account';
import Signin from './pages/Signin';

import Terms from './components/Terms';
import Privacy from './components/Privacy';
import CustomerService from './components/CustomerService';
import Refunds from './components/Refunds';
import Cards from './components/Cards';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar2 from './components/Navbar2';
import Newsletter from './components/Newsletter';

function App() {
  useEffect(() => {
    console.log(' App useEffect!!!!');

  }, []);
  return (
    <div>
      <AuthContextProvider>
        <Navbar2 />
        <Routes>
          <Route path='/' element={<Pages />} />
          <Route path='/signin' element={<Pages />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/pricing' element={<Cards isMarketing="true"/>} /> 
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/customerservice' element={<CustomerService />} />
          <Route path='/refunds' element={<Refunds />} />
          <Route
            path='/account'
            element={
              <Protected>
                <Account />
              </Protected>
            }
          />
        </Routes>
      </AuthContextProvider>
      <Footer />
    </div>
  );

  // return (
  //   <div>
  //     {/* <Navbar2 /> */}
  //     <Hero />
  //     {/* <Terms />
  //     <Newsletter />
  //     <Cards />
  //     <Footer /> */}
  //   </div>
  // );
}
const Pages = () => (
    <div>
      <Hero />
      <Cards />
    </div>
  );

export default App;
