import React from 'react';
import Laptop from '../assets/laptop.jpg';

const CustomerService = () => {
  const paragraphClassName = 'py-4';
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid'>
        <div className='flex flex-col justify-center'>
          <p className='text-[#00df9a] font-bold text-4xl'>Customer Service</p>
          <iframe src="https://copyeditai.zendesk.com/hc/en-us/requests/new" width="100%" height="1000px" title="Customer Service"></iframe>
        </div>
      </div>
    </div>
  );
};

export default CustomerService;
